import { getDetailTeam } from "@/api";
export default {
  data() {
    return {
      pages: 1,
      num: 8,
      total: 0,
      info: {},
      list: [],
      level: 0
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    setLevel(val) {
      this.level = val;
      this.getList();
    },
    getList() {
      this.$toast({
        message: '加载中...',
        type: "loading",
        forbidClick: true,
        duration: 0
      });
      getDetailTeam({
        level: this.level,
        pages: this.pages,
        num: this.num
      }).then(res => {
        this.$closeToast();
        this.list = res.data;
        this.info = {
          first_touzi_amount: res.first_touzi_amount,
          invest_num: res.invest_num,
          secend_invest_num: res.secend_invest_num,
          second_touzi_amount: res.second_touzi_amount,
          three_invest_num: res.three_invest_num,
          three_touzi_amount: res.three_touzi_amount,
          total_num: res.total_num
        };
        this.total = res.total_num;
        console.log(res);
      });
    }
  },
  computed: {
    pageCount() {
      return Math.ceil(this.total / this.num);
    }
  }
};